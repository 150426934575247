<template>
<v-list
    :model-value="modelValue"
    :density="isMobile ? 'comfortable' : 'compact'"
    @update:model-value="emit('update:modelValue', $event)"
    :nav="!inMenu"
>
    <v-list-item
        v-if="inMenu"
        :class="extraItemClass"
        color="secondary"
        :value="0"
        to="/account"
        exact
        rounded
    >
        Account Home
    </v-list-item>
    <template
        v-if="accountMenu"
        v-for="(value, key) in accountMenu"
        :key="key"
    >
        <template
            v-if="!value?.hideInMenu"
        >
            <v-list-subheader
                v-if="!value?.hideTitleInMenu"
            >
                <v-list-item-title
                    class="mt-6 mb-2 text-overline font-weight-bold"
                >
                    {{ value.title }}
                </v-list-item-title>
            </v-list-subheader>
            <template
                v-for="item in value.items"
                :key="item.id"
            >
                <v-list-item
                    v-if="!item?.hideInMenu"
                    :class="extraItemClass"
                    color="secondary"
                    :value="item.to"
                    :to="item.to"
                    exact
                    rounded
                >
                    {{ item.title }}
                </v-list-item>
            </template>
        </template>
    </template>
    <template v-else>
        <v-list-item>No Items</v-list-item>
    </template>
</v-list>
</template>

<script lang="ts" setup>
    import { useIsMobile, AccountMenu } from '~/composables';

    const props = withDefaults(
        defineProps<{
            modelValue?: Nilish<string>;
            inMenu?: boolean;
        }>(),
        {
            inMenu: false
        }
    );

    const emit = defineEmits<{
        'update:modelValue': [value: string];
    }>();

    const { isMobile } = useIsMobile();
    const accountMenu = AccountMenu();

    const extraItemClass = computed<string>(() => {
        const cls: string[] = [];
        if (!props.inMenu) {
            cls.push('text-uppercase letter-spacing-5 font-weight-medium');
            cls.push(isMobile.value ? 'font-size-7' : 'font-size-8');
        } else {
            cls.push('font-size-6');
        }

        return cls.join(' ');
    });
</script>

<style lang="scss"></style>
